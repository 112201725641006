<template>
  <div>
    <img :src="imgUrl" width="100%" />
    <audio ref="audio" :src="audioSrc" style="display: none"></audio>
    <FloatIcons padding="10 10 40 10" class="icons-warp">
      <div class="float-icon-item">
        <!-- <img style="width:30px" src="../assets/img/logo4.png" alt=""/> -->
        <span
          class="iconfont icon-bofang" style="font-size:30px"
          v-show="play"
          @click="startPlayOrPause"
        ></span>
        <span
          class="iconfont icon-ico_zanting_xuanzhong" style="font-size:30px"
          v-show="!play"
          @click="startPlayOrPause"
        ></span>
      </div>
      <div class="float-icon-item">
        <span class="iconfont icon-touxiang" style="font-size:34px" @click="showDlg"></span>
      </div>
    </FloatIcons>
    <van-dialog
      v-model="showUserDlg"
      :show-cancel-button="true"
      :beforeClose="beforeClose"
    >
      <div
        style="
          width: 100%;
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <span
          style="
            width: 100%;
            height: 40px;
            font-size: 1.2em;
            color: #ffffff;
            text-align: center;
            margin-bottom: 10px;
            display: inline-block;
            background-color: #ca5d56;
            line-height: 40px;
          "
        >
          个人信息</span
        >
        <van-radio-group v-model="isBirthday" direction="horizontal">
          <van-radio name="0">预产期</van-radio>
          <van-radio name="1">出生日期</van-radio>
        </van-radio-group>

        <input
          :value="timeValue"
          @click="showPopup"
          style="
            text-align: center;
            margin: 5px;
            border: none;
            border-radius: 0;
            border-bottom: #8d8d8d 1px solid;
          "
        />
      </div>
    </van-dialog>
	<van-popup v-model="showDate" position="bottom">
	  <van-datetime-picker
		v-model="currentDate"
		type="date"
		title="选择日期"
		:formatter="formatter"
		@cancel="showDate = false"
		@confirm="confirmPicker"
	  />
	</van-popup>
  </div>
</template>
<script>
import FloatIcons from "../components/FloatIcons";
import { getData, setUserInfo } from "../api/daily";

export default {
  components: { FloatIcons },
  data() {
    return {
      imgUrl: null,
      playlist: [],
      user: null,
      play: true,
      audioSrc: "http://file.yangyubiji.com/daily/s1.wav",
      showUserDlg: false,
      isBirthday: "1",
      code: null,

      isLoadingShow: true,
      showDate: false,
      currentDate: new Date(),
      className: "",
      timeValue: "",
      value1: "",
      value2: "",
      value3: "",
      query: {},
    };
  },
  created() {
    this.query = this.$route.query;
    let key = this.query.key;
    let appid = this.query.appid;
    console.log("appid" + appid);
    if (key == null || key == undefined) {
      return;
    }

    this.value1 = this.currentDate.getFullYear();
    this.value2 = this.currentDate.getMonth()+1;
    this.value3 = this.currentDate.getDate();
    this.timeValue = `${this.value1}年${this.value2}月${this.value3}日`;
    // checkKey(key).then((res) => {
    //   if (res==null || res.data == "false") {
    //     return;
    //   }
    // });

    var code = this.getUrlParam("code");
    var local = window.location.href;
    if (code == null || code == "") {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=state#wechat_redirect";
    } else {
      console.info("code:" + code);
      this.code = code;
      this.query.code = code;
      getData(this.query).then((res) => {
        console.info(res);
        if (res == null || res.data == null) {
          return;
        } else {
          this.user = res.data.user;

          if (this.user.type == null) {
            this.showUserDlg = true;
          } else {
            if (this.user.type == 0) {
              this.isBirthday = "0";
              this.timeValue = this.user.edc;
            } else {
              this.isBirthday = "1";
              this.timeValue = this.user.birthday;
            }
            this.imgUrl = res.data.daily.image;
			if(this.imgUrl !=null){
              this.audioSrc = this.imgUrl.replace("png","wav");
            }
          }
        }
      });
    }
  },
  methods: {
    startPlayOrPause() {
      if (this.play) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
      this.play = !this.play;
    },
    showDlg() {
      this.showUserDlg = !this.showUserDlg;
    },
    // 日期组件自定义格式
    formatter(type, value) {
      if (type === "year") {
        this.value1 = value; // 可以拿到当前点击的数值
        return `${value}年`;
      } else if (type === "month") {
        this.value2 = value;
        return `${value}月`;
      }
      this.value3 = value;
      return `${value}日`;
    },
    showPopup() {
      this.showDate = !this.showDate;
    },
    confirmPicker() {
      this.showDate = false;
      this.timeValue = `${this.value1}年${this.value2}月${this.value3}日`;
    },
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let url = window.location.href.split("#")[0];
      let search = url.split("?")[1];
      if (search) {
        var r = search.substr(0).match(reg);
        if (r !== null) return unescape(r[2]);
        return null;
      } else {
        return null;
      }
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        let param = { openId: this.user.openId };
        if (this.isBirthday == "1") {
          param.type = 1;
          param.birthday = this.value1 + "-" + this.value2 + "-" + this.value3;
        } else {
          param.type = 0;
          param.edc = this.value1 + "-" + this.value2 + "-" + this.value3;
        }

        setUserInfo(param).then((res) => {
          console.info(res);
          window.history.back();
        });
        this.showUserDlg = false;
      } else if (action === "cancel") {
        done();
      }
    },
  },
};
</script>
<style scoped>
.iconfont {
  color: #fff;
}
.float-icon-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.icons-warp {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
