import request from "./http";

// 校验接口key
export function checkKey(params){
    return request({
        url:'/third/custom/isValidKey',
        method:"get",
        params
    });
}

/**
 * 获取每日一信图片
 * @param {*} params 
 * @returns 
 */
export function getDaily(params){
    return request({
        url:'/third/custom/getDaily',
        method:"get",
        params
    });
}

/**
 * 获取微信用户openId
 * @param {*} params 
 * @returns 
 */
 export function getOpenid(params){
    return request({
        url:'/third/Wechat/getOpenid',
        method:"get",
        params
    });
}

/**
 * 获取微信用户信息
 * @param {*} params 
 * @returns 
 */
 export function getData(params){
    return request({
        url:'/third/Wechat/getData',
        method:"get",
        params
    });
}

export function queryData(params){
    return request({
        url:'/third/Wechat/queryData',
        method:"get",
        params
    });
}

/**
 * 设置微信用户信息
 * @param {*} params 
 * @returns 
 */
 export function setUserInfo(params){
    return request({
        url:'/third/Custom/setUserInfo',
        method:"post",
        params
    });
}

export function getUserInfo(params){
    return request({
        url:'/third/Custom/getUserInfo',
        method:"get",
        params
    });
}


